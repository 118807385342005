import coin1 from "../src/assets/images/500.png";
import coin2 from "../src/assets/images/1000.png";
import coin3 from "../src/assets/images/10k.png";
import coin4 from "../src/assets/images/50k.png";
import coin5 from "../src/assets/images/100k.png";
import { useEffect, useState } from "react";
import fruit3 from "../src/assets/images/lemon.png";
import fruit2 from "../src/assets/images/apple.png";
import fruit1 from "../src/assets/images/watermelon.png";
import SettingsComponent from "./SettingComponent";
import History from "./History";
import WinnerHistory from "./WinnerHistory";
import { ToastConent } from "./ToastConent";
import audioPath from "./assets/audio/coinsSound.mp3";

import wheel from "./assets/images/wheel.png";
import WinnerDialog from "./WinnerDialog";

let currentGame = [];
const queryParams = new URLSearchParams(window.location.search);
const userId = queryParams.get("id");

const Game = (props) => {
  const { userData, socket, settingData } = props;
  const gameCoin = [500, 1000, 10000, 50000, 100000];

  const [seconds, setSeconds] = useState(0);
  const [time, setTime] = useState();

  const [betsInFrames, setBetsInFrames] = useState([
    { frame: 1, totalBit: 0, userBit: 0, imageUrl: "" },
    { frame: 2, totalBit: 0, userBit: 0, imageUrl: "" },
    { frame: 3, totalBit: 0, userBit: 0, imageUrl: "" },
  ]);

  const handleSoundToggle = () => {
    setIsSoundChecked(!isSoundChecked);
    localStorage.setItem("sound", !isSoundChecked);
  };

  const [isVisible, setIsVisible] = useState(false);

  const [openHistory, setOpenHistory] = useState(false);
  const [userEmitSent, setUserEmitSent] = useState(false);

  const [userHistroyDialog, setUserHistoryDialog] = useState(false);
  const values = [500, 1000, 10000, 50000, 100000];

  const [betAmount, setBetAmount] = useState(values[0]);
  const [winner, setWinner] = useState(null);

  const [winnerIndex, setWinnerIndex] = useState(null);

  const imageArray = [coin1, coin2, coin3, coin4, coin5];
  const fruitArray = [
    fruit1,
    fruit2,
    fruit3,
    fruit1,
    fruit2,
    fruit3,
    fruit1,
    fruit2,
    fruit3,
  ];
  const [isAnimating, setIsAnimating] = useState(false);

  const [stopBet, setStopBet] = useState(false);

  const [isSoundChecked, setIsSoundChecked] = useState(() => {
    // Retrieve the current value from localStorage if it exists, else set it to true
    const initialValue = localStorage.getItem("sound");

    return initialValue !== null ? initialValue : true;
  });

  // Effect to run only on mount to initialize the 'sound' state
  useEffect(() => {
    localStorage.setItem("sound", isSoundChecked);
  }, []);

  const [gameFrame, setGameFrame] = useState([
    { selectFrame: 1, Bit: 0 },
    { selectFrame: 2, Bit: 0 },
    { selectFrame: 3, Bit: 0 },
  ]);

  const playSoundFunction = async () => {
    let audio = new Audio(audioPath);
    const isSoundChecked = localStorage.getItem("sound") === "true";
    audio.volume = isSoundChecked ? 1 : 0;
    const playPromise = audio.play();

    if (playPromise !== undefined) {
      try {
        await playPromise;
        // Audio started playing
      } catch (error) {
        // Handle potential errors
        console.error("Error playing audio:", error);
      }
    }
  };

  useEffect(() => {
    localStorage.setItem("sound", isSoundChecked);
  }, [isSoundChecked]);

  useEffect(() => {
    socket?.on("time", (time) => {
      updateTime(time);
      setTime(time);
    });
  }, [socket]);

  const [selectedAnimation, setSelectedAnimation] = useState(null);
  const [selectedAnimationFruits, setSelectedAnimationFruits] = useState(null);
  const [winnerObject, setWinnerObject] = useState([]);

  useEffect(() => {
    if (time === -1) {
      if (selectedAnimation === "rotet_while_1") {
        setWinner(1);
      } else if (selectedAnimation === "rotet_while_2") {
        setWinner(2);
      } else {
        setWinner(3);
      }
    }

    if (time === 0) {
      setStopBet(true);

      // Set a timeout to turn stopBet back to false after 2 seconds
      const timeout = setTimeout(() => {
        setStopBet(false);
      }, 2000); // 2 seconds

      // Cleanup the timeout when the component unmounts or `time` changes
      return () => clearTimeout(timeout);
    }

    if (time === 1) {
      socket.emit("winnerFrame", null);
    }

    if (time === -11) {
      socket?.emit("winner", {
        winner: winner,
      });
      setWinnerIndex(winner - 1);
      setIsAnimating(true);

      // Automatically reset the animation state after 3 seconds
      setTimeout(() => {
        setIsAnimating(false);
        setWinnerIndex(null);
      }, 4000); // Duration of the animation (4s)
    }
  }, [time]);

  socket?.off("game").on("game", (game) => {
    currentGame = game?.UsersBits;
    if (game) {
      setGameFrame([
        { selectFrame: 1, Bit: 0 },
        { selectFrame: 2, Bit: 0 },
        { selectFrame: 3, Bit: 0 },
      ]);
      updateGame(game);
      setUserEmitSent(false);
    }
  });

  useEffect(() => {
    socket?.on("winnerFrame", (data) => {
      if (data === 1) {
        setSelectedAnimation("rotet_while_1");
        setSelectedAnimationFruits("rotet_while_1_fruits");
      } else if (data === 2) {
        setSelectedAnimation("rotet_while_2");
        setSelectedAnimationFruits("rotet_while_2_fruits");
      } else {
        setSelectedAnimation("rotet_while_4");
        setSelectedAnimationFruits("rotet_while_4_fruits");
      }
    });

    socket?.on('winnerObject',(data) =>{
      console.log("winnerObject",data);
      if(data){

        setWinnerObject(data);
      }

    })
  }, [socket]);

  useEffect(() => {
    socket?.off("bit").on("bit", (data) => {
      setBetsInFrames((prevFrames) =>
        prevFrames.map((frame) => {
          const frameData = data.allBit.find(
            (bitFrame) => bitFrame.selectFrame === frame.frame
          );

          let imageUrl = "";
          if (frameData?.lastBit === 1) {
            imageUrl = coin1;
          } else if (frameData?.lastBit === 2) {
            imageUrl = coin2;
          } else if (frameData?.lastBit === 3) {
            imageUrl = coin3;
          } else if (frameData?.lastBit === 4) {
            imageUrl = coin4;
          } else if (frameData?.lastBit === 5) {
            imageUrl = coin5;
          }

          return {
            ...frame,
            totalBit: frameData ? frameData.bit : frame.totalBit,
            userBit:
              data.UserId === userId &&
              frameData?.selectFrame === data.SelectedFrame
                ? frame.userBit + data.Bit
                : frame.userBit,
            imageUrl: imageUrl, // Update imageUrl dynamically
          };
        })
      );
    });

    return () => {
      socket?.off("bit");
    };
  }, [socket, userId]);

  if (time === -13 && !userEmitSent) {
    socket?.emit("user", {
      _id: userData?._id,
    });
    setUserEmitSent(true);
  }

  useEffect(() => {
    if (time === -14) {
      setBetsInFrames([
        { frame: 1, totalBit: 0, userBit: 0, imageUrl: "" },
        { frame: 2, totalBit: 0, userBit: 0, imageUrl: "" },
        { frame: 3, totalBit: 0, userBit: 0, imageUrl: "" },
      ]);
      setUserEmitSent(false);
      setSelectedAnimation("");
      setSelectedAnimationFruits("");
      setWinner(null);
      setWinnerIndex(null);
      setIsAnimating(false);
    }
  }, [time]);

  const updateGame = (game) => {
    const findUser = game?.UsersBits?.filter((user) => {
      return user?.userId === userId;
    });
    findUser?.forEach((data) => {
      const { Bit, SelectedFrame } = data;
      const gameIndex = gameFrame?.findIndex(
        (frame) => frame?.selectFrame === SelectedFrame
      );
      if (gameIndex !== -1) {
        setGameFrame((prevFrame) =>
          prevFrame.map((frame) =>
            frame.selectFrame === SelectedFrame
              ? { ...frame, Bit: frame.Bit + Bit }
              : frame
          )
        );
      }
    });
  };

  const updateTime = (time) => {
    if (time === 25) {
      setBetAmount(gameCoin[0]);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);

  // Handler for image click
  const handleClick = (index, value) => {
    setActiveIndex(index === activeIndex ? null : index);
    setBetAmount(value);
  };

  const openSettings = () => {
    setIsVisible(true);
  };

  const handleClose = (data) => {
    setIsVisible(false);
  };

  const handleHistoryDialog = () => {
    setUserHistoryDialog(false);
  };

  const handleCloseHistoryDialogue = () => {
    setOpenHistory(false);
  };

  const handleFramClick = (fram, index) => {
    if (betAmount >= 0) {
      addBit(betAmount == 0 ? gameCoin[0] : betAmount, fram, index);
    }
  };

  const getAmount = (coin) => {
    let amount = 0;
    let imageIndex = 0;

    if (coin === gameCoin[0]) {
      amount = gameCoin[0];
      imageIndex = 1;
    } else if (coin === gameCoin[1]) {
      amount = gameCoin[1];
      imageIndex = 2;
    } else if (coin === gameCoin[2]) {
      amount = gameCoin[2];
      imageIndex = 3;
    } else if (coin === gameCoin[3]) {
      amount = gameCoin[3];
      imageIndex = 4;
    } else if (coin === gameCoin[4]) {
      amount = gameCoin[4];
      imageIndex = 5;
    } else {
      console.log("No match found for coin:", coin);
    }

    return [amount, imageIndex];
  };

  const addBit = (bitcoin, myframe, index) => {
    const [amount, imageIndex] = getAmount(bitcoin);

    if (bitcoin !== -1 && myframe !== 0) {
      if (userData && userData?.diamond - amount >= 0) {
        playSoundFunction();
        userData.diamond -= amount;

        // Emit the bet event to the server
        socket?.emit("bit", {
          User: userData,
          Bit: bitcoin,
          SelectedFrame: myframe,
          image: imageIndex,
        });
      } else {
        ToastConent(
          "You don't have enough diamonds. Please recharge!",
          "warning"
        );
      }
    } else {
      ToastConent("Please select a valid coin.", "info");
    }
  };

  useEffect(() => {
    const hiddenInfoElement = document.getElementById("hidden_info_here1");
    const hiddenInfoElement2 = document.getElementById("hidden_info_here2");

    if (time === 2) {
      // Remove the 'd-none' class to make it visible
      hiddenInfoElement?.classList.remove("d-none");

      // Add the 'd-none' class back after 2 seconds
      const timeout = setTimeout(() => {
        hiddenInfoElement?.classList.add("d-none");
      }, 4000);

      // Cleanup function to clear the timeout if the effect runs again
      return () => clearTimeout(timeout);
    }

    if (time === -14) {
      // Remove the 'd-none' class to make it visible
      hiddenInfoElement2?.classList.remove("d-none");

      // Add the 'd-none' class back after 2 seconds
      const timeout = setTimeout(() => {
        hiddenInfoElement2?.classList.add("d-none");
      }, 4000);

      // Cleanup function to clear the timeout if the effect runs again
      return () => clearTimeout(timeout);
    }

    // Ensure the class is hidden if `time` is not 0
    hiddenInfoElement?.classList.add("d-none");
    hiddenInfoElement2?.classList.add("d-none");
  }, [time]);

  return (
    <div className="saven_winner gameconatiner" id="saven_winner">
      {openHistory && (
        <History onCloseHistory={handleCloseHistoryDialogue} userId={userId} />
      )}
      {isVisible && (
        <SettingsComponent
          childToParent={handleClose}
          handleSoundToggle={handleSoundToggle}
          isSoundChecked={isSoundChecked}
        />
      )}

      {userHistroyDialog && <WinnerHistory onClose={handleHistoryDialog} />}
      { time < -10 && time > -15 && <WinnerDialog winners={winnerObject || []} /> }

      {/* <WinnerDialog /> */}
      <div className="container gamemain ">
        <div className="userCoin" style={{ text: "center", color: "black" }}>
          {userData?.coin}
        </div>
        <div className="header_r">
          <div className="header_left"></div>
          <div id="logs-container"></div>
          <div className="header_right">
            <div
              style={{ position: "relative" }}
              className="icons icons_header_right"
            >
              <ul
                className="header_right_icons_ul "
                style={{
                  right: "10px",
                  top: "180px",
                  flexDirection: "column-reverse",
                }}
              >
                {/* <li
                  className="mb-2 icons_header_right_click_4"
                  onClick={() => setUserHistoryDialog(!userHistroyDialog)}
                >
                  <img
                    className="setng"
                    src="https://rklive.site/public/game/new/image/users.png"
                    style={{ width: "80px" }}
                    alt=""
                  />
                </li> */}
                <li
                  className="mb-2 icons_header_right_click_1"
                  onClick={() => openSettings()}
                >
                  <img
                    className="setng"
                    src="https://rklive.site/public/game/new/image/setting.png"
                    style={{ width: "80px" }}
                    alt=""
                  />
                </li>
                <li
                  className=" icons_header_right_click_3"
                  onClick={() => setOpenHistory(true)}
                >
                  <img
                    className="setng"
                    src="https://rklive.site/public/game/new/image/help.png"
                    style={{ width: "80px" }}
                    alt=""
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="body" style={{ transform: "translateY(-80px)" }}>
          <div className="body_middle">
            <div className="images" style={{ zIndex: 0 }}>
              {/* Main Wheel Image */}
              <img
                src={wheel}
                alt="Saven Winner"
                style={{ zIndex: 10, position: "inherit" }}
              />

              {/* Spinning Inner Wheel */}
              <img
                className="spinner_while"
                src="https://rklive.site/public/game/new/image/while_in.png"
                alt="Saven Winner"
                style={{
                  zIndex: -1,
                  animation:
                    time < 0 &&
                    time > -15 &&
                    `7s ease 0s 1 normal forwards running ${selectedAnimation}`,
                  filter: "drop-shadow(black 0px 0px 1px)",
                }}
              />

              <div
                id="all_animation_foots"
                style={{
                  animation:
                    time < 0 &&
                    time > -15 &&
                    `7s ease 0s 1 normal forwards running ${selectedAnimationFruits}`,
                }}
              >
                {Array.from({ length: 9 }).map((_, index) => (
                  <span
                    key={index}
                    className={`fruit${index + 1}`}
                    style={{
                      "--i": index + 1,
                      filter: "drop-shadow(black 0px 0px 1px)",
                    }}
                  >
                    <img
                      src={fruitArray[index]}
                      alt={`Fruit ${(index % 3) + 1}`}
                      style={{ filter: "drop-shadow(black 0px 0px 1px)" }}
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className={`body_bottom ${time - 2 < 1 && "opacity-0"}`}>
            <div className="images">
              <img
                className="clock"
                src="https://rklive.site/public/game/new/image/clock.png"
                alt="Saven Winner"
              />
              <h2 className="header clock_time_count_down">{time - 2}</h2>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="footer_top" disabled={time < 3}>
            {betsInFrames.map((frame, index) => (
              <button
                key={index}
                onClick={() => handleFramClick(frame.frame, index)}
                className={`box_wrapper box_wrapper${index} ${
                  time < 3 ? "disabled" : ""
                }`}
                disabled={time < 3}
                style={{
                  pointerEvents: time < 1 ? "none" : "auto", // Disables user interaction
                  opacity: time < 3 && !isAnimating ? 0.5 : 1, // Dim appearance if disabled
                  animation:
                    winner &&
                    winner !== null &&
                    index === winner - 1 &&
                    isAnimating &&
                    "4s ease 0s 1 normal forwards running box_animation_apple",
                  filter:
                    index !== winner - 1 && isAnimating && "grayscale(100%)",
                }}
              >
                {/* User's Specific Bet */}
                <div className="box_wrapper_header">
                  <h2 className="header">{frame.totalBit || 0} </h2>
                </div>

                <div className="box_wrapper_body">
                  {frame.totalBit > 0 && (
                    <img
                      src={frame?.imageUrl}
                      alt="Bet Coin"
                      className={`coin_box${index} bet_images`}
                      style={{
                        width: "24px",
                        height: "24px",
                        objectFit: "contain",
                        filter: "drop-shadow(0px 0px 2px black)",
                      }}
                    />
                  )}
                </div>

                {/* Total Frame Bets */}
                <div className="box_wrapper_footer">
                  <h2 className="header">
                    {frame.userBit || 0} {/* Total bets for the frame */}
                  </h2>
                </div>
              </button>
            ))}
          </div>

          <div className="footer_bottom">
            <div className="footer_bottom_left">
              <div className="footer_bottom_left_right">
                <div className="footer_bottom_left_right_bottom">
                  <img
                    src="https://rklive.site/public/game/new/image/bt.png"
                    alt="Saven Winner"
                  />
                  <input
                    style={{ color: "white" }}
                    type="text"
                    id="total_amount"
                    value={userData?.diamond}
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="footer_bottom_right">
              {values.map((value, index) => (
                <div
                  key={index}
                  className={`images ${activeIndex === index ? "active" : ""}`}
                  onClick={() => handleClick(index, value)}
                >
                  <input type="hidden" value={value} />
                  <img src={imageArray[index]} alt="Saven Winner" />
                  {/* Conditionally render animation wrapper if active */}
                  {activeIndex === index && (
                    <div id="btn_animation_wrapper">
                      <div className="animation">
                        {[...Array(5)].map((_, i) => (
                          <span
                            key={i}
                            style={{ "--i": i + 1 }}
                            className={`animateCoin${i + 1}`}
                          >
                            <i
                              className="fa-solid fa-play"
                              style={{ fontSize: "10px" }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
              <div className="icons_header_right_click_2">
                <img
                  src="https://rklive.site/public/game/new/image/ranking.png"
                  className="rankingsdf"
                  alt="Saven Winner"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          id="hidden_info_here1"
          className={`roatenoe Reminder_Here This_is_notification d-none `}
          style={{ width: "100%" }}
        >
          <div
            className="container"
            style={{
              border: "1px solid gold",
              borderRadius: "0",
              borderLeft: "none",
              borderRight: "none",
              background:
                "linear-gradient(90deg, rgb(229 57 130 / 48%) 0%, rgb(77 16 57) 23%, rgba(89,19,63,1) 90%, rgb(229 57 130 / 23%) 100%)",
            }}
          >
            <div className="body">
              <p className="title text-white">Stop Batting</p>
            </div>
          </div>
        </div>

        <div
          id="hidden_info_here2"
          className="roatenoe Reminder_Here This_is_notification "
          style={{ width: "100%" }}
        >
          <div
            className="container"
            style={{
              border: "1px solid gold",
              borderRadius: "0",
              borderLeft: "none",
              borderRight: "none",
              background:
                "linear-gradient(90deg, rgb(229 57 130 / 48%) 0%, rgb(77 16 57) 23%, rgba(89,19,63,1) 90%, rgb(229 57 130 / 23%) 100%)",
            }}
          >
            <div className="body">
              <p className="title text-white">Start Batting</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
