import React, { useEffect } from "react";
import "./assets/css/winnerDialog.css";

// Update component to accept winners array instead of single winner
const WinnerDialog = ({ winners = [], onClose = () => {} }) => {
  // Remove mock data since we'll use real data

  // Update sorting to use correct data structure
  const validWinners = Array.isArray(winners)
    ? [...winners]
        .sort((a, b) => (b?.winnings || 0) - (a?.winnings || 0))
        .slice(0, 3) // Take only top 3
    : [];

  console.log("winners", validWinners);

  useEffect(() => {
    const createFirecracker = (container) => {
      for (let i = 0; i < 20; i++) {
        const particle = document.createElement('div');
        particle.className = 'confetti';
        
        // Random angle for circular burst
        const angle = (Math.PI * 2 * i) / 20;
        
        // Random distance from center
        const x = Math.cos(angle) * (Math.random() * 50 + 20);
        const y = Math.sin(angle) * (Math.random() * 50 + 20);
        
        // Random colors for firecracker effect
        const colors = ['#ff4444', '#ffaa00', '#ffff00', '#ff8844'];
        const color = colors[Math.floor(Math.random() * colors.length)];
        
        particle.style.setProperty('--x', `${x}px`);
        particle.style.setProperty('--y', `${y}px`);
        particle.style.backgroundColor = color;
        
        // Random size
        const size = Math.random() * 4 + 4;
        particle.style.width = `${size}px`;
        particle.style.height = `${size}px`;
        
        // Random delay for more natural effect
        particle.style.animationDelay = `${Math.random() * 0.2}s`;
        
        container.appendChild(particle);
        
        // Remove particle after animation
        particle.addEventListener('animationend', () => {
          particle.remove();
        });
      }
    };

    const startFirecracker = () => {
      validWinners.forEach((winner) => {
        const container = document.querySelector(
          `.winner-container[data-id='${winner.user?.id}']`
        );
        if (container) {
          createFirecracker(container);
        }
      });
    };

    if (validWinners.length > 0) {
      startFirecracker();
      const interval = setInterval(startFirecracker, 2000);
      const timer = setTimeout(() => {
        onClose();
      }, 4000);

      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
  }, [validWinners, onClose]);

  // Helper function to get position-specific styles
  const getPositionStyles = (index) => {
    const positions = {
      0: { // 1st place
        scale: '1.1',
        order: '2',
        trophy: '🏆',
        color: '#FFD700', // Gold
        borderColor: '#FFD700'
      },
      1: { // 2nd place
        scale: '0.9',
        order: '1',
        trophy: '🥈',
        color: '#C0C0C0', // Silver
        borderColor: '#C0C0C0'
      },
      2: { // 3rd place
        scale: '0.9',
        order: '3',
        trophy: '🥉',
        color: '#CD7F32', // Bronze
        borderColor: '#CD7F32'
      }
    };
    return positions[index] || positions[0];
  };

  // Remove the early return and replace with conditional rendering
  return (
    <div className="winner-overlay">
      <div className="winner-content">
        {validWinners.length > 0 ? (
          validWinners.map((winner, index) => {
            const positionStyle = getPositionStyles(index);
            return (
              <div 
                key={winner.user?.id || Math.random()} 
                className="winner-container" 
                data-id={winner.user?.id}
                style={{
                  transform: `scale(${positionStyle.scale})`,
                  order: positionStyle.order,
                  borderColor: positionStyle.borderColor,
                  boxShadow: `0 0 20px ${positionStyle.color}40`
                }}
              >
                <div className="winner-position" style={{ color: positionStyle.color }}>
                  {positionStyle.trophy}
                </div>
                <div className="winner-image">
                  <img 
                    src={winner.user?.image || 'default-avatar-url.png'} 
                    alt={winner.user?.name} 
                    onError={(e) => {
                      e.target.src = 'default-avatar-url.png';
                    }}
                    style={{ borderColor: positionStyle.color }}
                  />
                </div>
                <span className="winner-name">{winner.user?.name}</span>
                <div className="winner-winnings" style={{ color: positionStyle.color }}>
                  {(winner.winnings || 0).toLocaleString()} 💎
                </div>
                <div className="celebration-container">
                  <h1 className="winner-text">Congratulations!</h1>
                </div>
              </div>
            )
          })
        ) : (
          <div className="no-winner-container">
            <div className="sad-emoji">😔</div>
            <h1 className="no-winner-text">Oops! No One Wins</h1>
            <p className="no-winner-subtext">Better luck next time!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WinnerDialog;
