import React from 'react';

const WinnerHistory = ({ props ,onClose }) => {
    // Static Users Data
    const users = [
        { image: 'https://rklive.site/store/profile/aqOa3HLAxccEqmdiXDIgWqduxLr1D7z9rH8e2FYm.jpg', name: '🇧🇩❀' },
        { image: 'https://rklive.site/store/profile/BYkyebSH8Am2weiZ1rIRG7h7RBaFFK1rFrCVFk1z.jpg', name: '𝄞🅢︎' },
        { image: 'https://rklive.site/store/profile/sMyBN39STNiZd9Ux7RnNne0sKfPHR9QiWHT9QXSb.jpg', name: ',💯ℒ' },
        { image: 'https://rklive.site/store/profile/AAlFkcLJ6yV0kL16me2PvSoHnFiX5R9TW2BUiNGe.jpg', name: 'আজব' },
        { image: 'https://rklive.site/store/profile/yGewjsrRMB75CE4YWS0jEBeRRQk8xNjbldcX1Agd.jpg', name: '➳ᴹ𝐚' },
        { image: 'https://rklive.site/store/profile/5hyWTJa8IoBG0EtvhRMX2QLqePdDLhfEhBr07qy6.jpg', name: '❀ℳᏦ' },
        { image: 'https://rklive.site/store/profile/GoWYjVj4fa5wKdNEps2EohxMfsWXq5QaiihpTH9i.jpg', name: '꧁🅢🅛' },
        { image: 'https://rklive.site/store/profile/gyK58iWPIBZoGbWUkliSoWmMhE7vlpUzRdLv7Tpy.jpg', name: '꧁🅢🅛' },
        { image: 'https://rklive.site/store/profile/piQljmJVRwxiJekyDjGVq1xq67cAl7z5Bj09X1pu.jpg', name: '💫𝐌𝐑' },
        { image: 'https://rklive.site/store/profile/ODoKDsUaDlMccLVeA1dybYjtjIdTtSVcHaTaylX6.jpg', name: 'Rah' },
        { image: 'https://rklive.site/store/profile/A7SdsXP1NJM01nJXfbZIGdqUAp6pZ1v4FgBmXbD7.jpg', name: '༺𝐉𝐀' },
        { image: 'https://rklive.site/store/profile/mn5xR0Q4T0hsPSV1rxzhIagOucX9AfWqNE20hIuC.jpg', name: '꧁🅢🅛' },
        { image: 'https://rklive.site/store/profile/fdvrKRGLvXZelnGMxEr0vVqNulMsfl7FYPjSJhyq.jpg', name: '𝐋𝐁🇧' },
        { image: 'https://rklive.site/store/profile/SmVHC3FVgQ9gKdg8nW0PiYYsP0UgiB2xHPqyctqH.jpg', name: '❀FᏗ' },
        { image: 'https://rklive.site/store/profile/j0QmawoGs9N9biBan90IjKgZYb9GAZbF92bQVWLq.jpg', name: '꧁🅢🅛' },
        { image: 'https://rklive.site/store/profile/JJZw9Oqz6zBo21KhDrUIfsBK8euT3sXphXKY5HnT.jpg', name: '❀ℳᏦ' },
        { image: 'https://rklive.site/store/profile/6SkztcCdNLNUFUo35GijQDXXzIC9pfVmt6LWyqwy.jpg', name: '♛ᎷᏖ' },
        { image: 'https://rklive.site/store/profile/HlFQRCzqH6BWD1HzvlHprNZZOhBnobF3L1mGZnet.jpg', name: 'প্র' },
        { image: 'https://rklive.site/store/profile/default.png', name: 'Mon' },
        { image: 'https://rklive.site/store/profile/pIVBKnIv4478otGJvMsP6nem76bs6ZrRKmnalliY.jpg', name: '♛ᎷᏖ' },
    ];

    return (
        <div id="hidden_info_here" className="roatenoe users_here" style={{ width: '60%' }}>
            <div className="container">
            <button className='closeDialog' onClick={() => {
                    console.log("Close button clicked");
                    // setOpen(false);
                    onClose(false)
                }}>

                    <img
                        src="https://rklive.site/public/game/new/image/close.png"
                        className=""
                        style={{ width: '30px', cursor: 'pointer', zIndex: '999999' }}
                        alt="Close"

                    />
                </button>
                {/* Scrollable Users List */}
                <div
                    className="body"
                    style={{
                        width: '100%',
                        alignItems: 'flex-start',
                        overflowY: 'scroll',
                        height: '100%',
                    }}
                >
                    <div className="users_box w-100">
                        {users.map((user, index) => (
                            <div key={index} className="box_r">
                                <img
                                    src={user.image}
                                    alt={user.name}
                                    style={{
                                        width: '70px',
                                        height: '70px',
                                        objectFit: 'cover',
                                    }}
                                />
                                <p className="title">{user.name}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WinnerHistory;
