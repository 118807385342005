import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL, key } from "./Config";
import { ReactComponent as CloseIcon } from "./assets/Close.svg";
import fruit3 from "../src/assets/images/lemon.png";
import fruit2 from "../src/assets/images/apple.png";
import fruit1 from "../src/assets/images/watermelon.png";

const History = ({ props, userId, onCloseHistory }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    axios
      .get(`${baseURL}api/history?userId=${userId}`, {
        headers: {
          key: key,
        },
      })
      .then((response) => {
        const data = response?.data?.data;
        console.log(data);
        setHistory(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userId]);

  function dateFunction(dateString) {
    const dateObject = new Date(dateString);
    const timeString = dateObject.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return timeString;
  }

  return (
    <div
      id="hidden_info_here"
      className="roatenoe Rules_here"
      style={{ width: "80%" }}
    >
      <div className="container p-0 historyDialog" style={{marginTop:"50px"}} >
        <button className="closeDialog" onClick={onCloseHistory}>
          <img
            src="https://rklive.site/public/game/new/image/close.png"
            className=""
            style={{ width: "30px", cursor: "pointer", zIndex: "999999" }}
            alt="Close"
          />
        </button>
        <div
          className="body"
          style={{
            width: "100%",
            alignItems: "flex-start",
            height: "100%",
            overflowY: "scroll",
            justifyContent: "flex-start",
          }}
        >
          <div className="rules-model">
            <div className="rulesModelBox">
              <div className="model-body">
                <table>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Time</th>
                      <th>Result</th>
                      <th>Amount</th>
                      <th>Win</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history ? (
                      history?.map((item, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {item?.date ? dateFunction(item?.date) : ""}
                            </td>
                            <td>
                              <div className="winner-Box">
                                <span className="winner-number">
                                  <img
                                    height={20}
                                    width={20}
                                    src={
                                      item?.fruitloopGame?.winNumber?.winner ==
                                      1
                                        ? fruit1
                                        : item?.fruitloopGame?.winNumber
                                            ?.winner == 2
                                        ? fruit2
                                        : fruit3
                                    }
                                  />
                                </span>
                              </div>
                            </td>
                            <td>{item?.fruitloopGame?.totalAddAmount}</td>
                            <td
                              style={{
                                fontWeight: "500",
                                color: `${
                                  item?.fruitloopGame?.winCoin > 0
                                    ? "orange"
                                    : "black"
                                }`,
                              }}
                            >
                              {`${"+" + item?.fruitloopGame?.winCoin}`}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <h6 className="not-history">No History...</h6>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default History;
