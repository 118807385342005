import React, { useState } from 'react';

const SettingsComponent = ({ props, childToParent,handleSoundToggle,isSoundChecked }) => {


    return (
        <div id="hidden_info_here" className="roatenoe Settings_Here" style={{ width: '70%', cursor: 'pointer' }}>
            <div className="container">
                <button className='closeSetting' onClick={() => {
                    console.log("Close button clicked");
                    // setOpen(false);
                    childToParent(false)
                }}>

                    <img
                        src="https://rklive.site/public/game/new/image/close.png"
                        className=""
                        style={{ width: '30px', cursor: 'pointer', zIndex: '999999' }}
                        alt="Close"

                    />
                </button>
                <div style={{ width: '100%' }} className="body">
                    {/* <div className="music">
                        <p className="header">Music:</p>
                        <input
                            type="checkbox"
                            className="music_checkbox music_1_checkbox"
                            checked={isMusicChecked}
                            onChange={handleMusicToggle}
                        />
                    </div> */}
                    <div style={{ marginTop: '.5rem' }} className="music">
                        <p className="header">Sound:</p>
                        <input
                            type="checkbox"
                            className="music_checkbox sound_checkbox"
                            checked={isSoundChecked}
                            onChange={handleSoundToggle}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingsComponent;
